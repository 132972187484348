import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { ApplicationContext, ExternalLink } from '@product-site-frontend/shared';

import Styled from '../SectionLeasingTabs.styled';

TariffsTab.propTypes = {
  onSwitchToCalc: PropTypes.func,
};

export default function TariffsTab({ onSwitchToCalc }) {
  const { setAmount, setApplicationOpen } = useContext(ApplicationContext);

  const handleApplicationClick = useCallback(() => {
    setAmount('');
    setApplicationOpen(true);
  }, [setApplicationOpen, setAmount]);

  return (
    <Box>
      <Typography
        sx={{ mb: { xs: 4, md: 5, lg: 6, textAlign: 'center' } }}
        variant="h2"
      >
        Тарифы и условия по стандартным программам
      </Typography>
      <Box sx={{ mb: 7 }}>
        <Typography
          component={ExternalLink}
          href={`${process.env.GATSBY_DOCUMENTS_PUBLIC_URL}/tarrifs.pdf`}
          sx={Styled.Link}
          variant="text"
        >
          <PictureAsPdfIcon fontSize="large" htmlColor="#ff6600" sx={{ mr: 2 }} />
          {' '}
          Подробные условия по тарифам
        </Typography>
      </Box>
      <Box sx={Styled.ButtonsContainer}>
        <Button
          color="secondary"
          onClick={onSwitchToCalc}
          size="large"
          sx={Styled.HelpButton}
          variant="outlined"
        >
          Рассчитать
        </Button>
        <Button
          color="primary"
          onClick={handleApplicationClick}
          size="large"
          sx={Styled.ApplicationButton}
          variant="contained"
        >
          Подать заявку
        </Button>
      </Box>
    </Box>
  );
}