const Tabs = {
  background: '#f6f6f6',
  width: { xs: '100%', lg: 'calc(100% + 224px)' },
  ml: { xs: 0, lg: '-112px' },
  px: { xs: 0, lg: '112px' },

  '& .MuiTabs-flexContainer': {
    justifyContent: { xs: 'normal', lg: 'center' },
  },

  '& .MuiTabs-indicator': {
    display: 'none',
  },
}

const Tab = {
  fontSize: { lg: 25, xs: 18 },
  fontWeight: 900,
  letterSpacing: '-0.31px',
  lineHeight: { lg: '54px', xs: 1.25 },
  maxWidth: 'unset',
  padding: { lg: '12px 50px', xs: '12px 16px' },
  textTransform: 'none',
}

const Table = {
  StickyCell: {
    position: 'sticky',
    left: 0,
    background: 'white',
    boxShadow: '5px 2px 5px grey',
  },
  Heading: {
    Cell: {
      color: '#ff6600',
      fontSize: 20,
      fontWeight: 700,
      minWidth: 220,
      textAlign: 'center',

      '&:not(:last-child)': {
        borderRight: '1px solid #e8e8e8',
      },
    },
  },
  Body: {
    Row: {
      verticalAlign: 'baseline',

      '& td, & th': {
        borderBottom: '1px solid #e8e8e8',
      },

      '&:last-child td, &:last-child th': {
        borderBottom: 0,
      },
    },
    RowName: {
      color: '#7d7d7d',
      fontSize: 18,
      fontWeight: 400,
    },
    Cell: {
      fontSize: 18,
      fontWeight: 700,
      textAlign: 'center',

      '&:not(:last-child)': {
        borderRight: '1px solid #e8e8e8',
      },
    },
  },
}

const ButtonsContainer = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  alignItems: 'center',
  justifyContent: 'center',
  mt: 7,
}

const HelpButton = {
  borderRadius: '11px',
  fontSize: { xs: 20, sm: 18, md: 22 },
  px: { xs: '40px', sm: '25px', md: '40px' },
  textTransform: 'none',
  mr: { xs: 0, sm: 3 },
  mb: { xs: 3, sm: 0 },
}

const ApplicationButton = {
  borderRadius: '11px',
  fontSize: { xs: 20, sm: 18, md: 22 },
  px: { xs: '40px', sm: '25px', md: '40px' },
  textTransform: 'none',
}

const Link = {
  display: 'flex',
  alignItems: 'center',
  color: '#4a90e2',
  fontSize: { xs: 18, sm: 20 },
  fontWeight: 500,
}

export default {
  Tabs,
  Tab,
  Table,
  ButtonsContainer,
  HelpButton,
  ApplicationButton,
  Link,
}