import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Tab, Tabs } from '@material-ui/core';
import SectionContainer from '@product-site-frontend/shared/components/SectionContainer';
import { useLocation } from '@reach/router';

import { scrollTo } from '../../utils/scrollTo';
import { LeasingCalcProvider } from './LeasingCalcContext';
import Styled from './SectionLeasingTabs.styled';
import { BusinessRequirementsTab, LeasingCalculatorTab, TariffsTab } from './Tabs';

function TabPanel({ children, index, value, ...rest }) {
  return (
    <div hidden={value !== index} {...rest}>
      {value === index && (
        <Box sx={{ marginTop: { lg: '75px', xs: '20px' } }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

export default function SectionLeasingTabs({ rate }) {
  const { hash } = useLocation();
  const tabsRef = useRef(null);

  const [activeTabIdx, setActiveTabIdx] = useState(0);
  const handleChangeTab = useCallback((_, newValue) => {
    setActiveTabIdx(newValue);
  }, [setActiveTabIdx]);

  const handleSwitchToCalcTab = useCallback(() => {
    setActiveTabIdx(0);
    scrollTo(tabsRef);
  }, [setActiveTabIdx]);

  const handleSwitchToTariffsTab = useCallback(() => {
    setActiveTabIdx(1);
    scrollTo(tabsRef);
  }, [setActiveTabIdx]);

  const handleSwitchToBusinessRequirementsTab = useCallback(() => {
    setActiveTabIdx(2);
    scrollTo(tabsRef, 'auto');
  }, [setActiveTabIdx]);

  useEffect(() => {
    if (hash === '#business-requirements' && tabsRef) {
      handleSwitchToBusinessRequirementsTab();
    }
  }, [hash, handleSwitchToBusinessRequirementsTab]);

  return (
    <SectionContainer id={"sectionLeasingTabs"}>
      <LeasingCalcProvider>
        <Box sx={{ width: '100%' }}>
          <Box ref={tabsRef}>
            <Tabs
              onChange={handleChangeTab}
              scrollButtons="auto"
              sx={Styled.Tabs}
              value={activeTabIdx}
              variant="scrollable"
            >
              <Tab label="Калькулятор" sx={Styled.Tab} />
              <Tab label="Тарифы и условия" sx={Styled.Tab} />
              <Tab label="Требования к бизнесу" sx={Styled.Tab} />
            </Tabs>
          </Box>
          <TabPanel index={0} value={activeTabIdx}>
            <LeasingCalculatorTab onSwitchToTariffs={handleSwitchToTariffsTab} rate={rate} />
          </TabPanel>
          <TabPanel index={1} value={activeTabIdx}>
            <TariffsTab onSwitchToCalc={handleSwitchToCalcTab} />
          </TabPanel>
          <TabPanel index={2} value={activeTabIdx}>
            <BusinessRequirementsTab />
          </TabPanel>
        </Box>
      </LeasingCalcProvider>
    </SectionContainer>
  );
};

SectionLeasingTabs.propTypes = {
  rate: PropTypes.number.isRequired
}