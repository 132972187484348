import React, { useCallback, useContext } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { ApplicationContext } from '@product-site-frontend/shared';

import Styled from '../SectionLeasingTabs.styled';

export default function BusinessRequirementsTab() {
  const { setAmount, setApplicationOpen } = useContext(ApplicationContext);

  const handleApplicationClick = useCallback(() => {
    setAmount('');
    setApplicationOpen(true);
  }, [setApplicationOpen, setAmount]);

  return (
    <Box>
      <Typography
        sx={{ mb: { xs: 4, md: 5, lg: 6, textAlign: 'center' } }}
        variant="h2"
      >
        Требования к бизнесу
      </Typography>
      <Typography sx={{ fontSize: 18 }} variant="body1">
        Мы работаем с микро, малым и средним бизнесом, индивидуальными{' '}
        предпринимателями, а также государственными учреждениями. У вас{' '}
        должно быть оформлено юридическое лицо или ИП. Однако, даже если{' '}
        вы только собираетесь открывать свой бизнес и хотите получить{' '}
        финансирование оборудования — оставьте заявку. Мы работаем и со{' '}
        стартапами, у которых есть убедительный бизнес-план. На момент{' '}
        оформления договора лизинга у вас должен быть открыт действующий{' '}
        расчетный счет.<br /><br />

        Чтобы заключить лизинговую сделку, важно следующее:<br /><br />

        - У вас должно быть оформлено юридическое лицо или ИП;<br />

        - Если вы только собираетесь открывать свой бизнес, еще не{' '}
        зарегистрировали компанию и являетесь стартапом — предоставьте{' '}
        нам бизнес-план;<br />

        - На момент оформления договора лизинга у вас должен быть открыт{' '}
        действующий расчетный счет.<br /><br />

        Вы выбираете производителя и оборудование, которое вам подходит,{' '}
        мы заключаем договор лизинга с вашей компанией и договор поставки{' '}
        с поставщиком. После оплаты первоначального взноса вы сможете{' '}
        пользоваться техникой и получать доход. На подготовку лизинговой{' '}
        сделки уходит гораздо меньше времени, чем на получение кредита.{' '}
        Нужно только загрузить небольшой пакет документов, и уже в течение{' '}
        1 дня сделка будет оформлена.<br /><br />

        Оставьте заявку, и мы поможем вам построить успешный бизнес без{' '}
        больших единовременных вложений.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 7 }}>
        <Button
          color="primary"
          onClick={handleApplicationClick}
          size="large"
          sx={Styled.ApplicationButton}
          variant="contained"
        >
          Подать заявку
        </Button>
      </Box>
    </Box>
  );
}